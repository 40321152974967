import React          from 'react';
import Layout         from '../components/Layout/Layout';
import { graphql }    from 'gatsby';
import Helmet         from 'react-helmet';
import './contatti.scss';
import { useIntl }    from "gatsby-plugin-intl";
import { warning200 } from '../utils/colors';
import HomepageBlock  from '../components/Homepage/HomepageBlock/HomepageBlock';
import { navigate }   from 'gatsby-link';

const Contatti = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout categories={data.prismic.allMacrocategorys.edges.map(el => el.node)}
            documents={data.prismic.allDocumentss.edges[0].node}>
      <Helmet title={'Contatti'} titleTemplate={`Assibro | %s`} meta={[
        {
          name: `description`,
          content: 'Assibro',
        },
        {
          property: `og:title`,
          content: 'Assibro',
        },
        {
          property: `og:description`,
          content: 'Assibro',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Assibro',
        },
        {
          name: `twitter:title`,
          content: 'Assibro',
        },
        {
          name: `twitter:description`,
          content: 'Assibro',
        },
      ]}/>
      <div className="contacts">
        <div className="contacts__hero">
          <div className="contacts__hero__caption">
            <h1>{intl.formatMessage({id: 'contacts.hero-title'})}</h1>
            <p>{intl.formatMessage({id: 'contacts.hero-text'})}</p>
          </div>
        </div>
        <div className="contacts__content">
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-1'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-1'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-2'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-2'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-3'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-3'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-4'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-4'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-5'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-5'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-6'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-6'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-7'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-7'})}}></p>
          </div>
          <div>
            <label dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-title-8'})}}></label>
            <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'contacts.detail-text-8'})}}></p>
          </div>
        </div>
        <HomepageBlock mode={'cta-handshakes'}
                        title={intl.formatMessage({ id: 'homepage.sixth-hero-title' })}
                        description={[intl.formatMessage({ id: 'homepage.sixth-hero-subtitle' })]}
                        bulletList={[{
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-1' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-1-text' }),
                        }, {
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-2' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-2-text' }),
                        }, {
                          title: intl.formatMessage({ id: 'homepage.sixth-hero-li-3' }),
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-li-3-text' }),
                        }]}
                        cta={{
                          text: intl.formatMessage({ id: 'homepage.sixth-hero-cta' }),
                          action: () => navigate('/contattaci'),
                        }}
                        hasBg={warning200}/>
      </div>
    </Layout>
  );
};

export default Contatti;

export const query = graphql`
    {
        prismic {
            allMacrocategorys(sortBy: position_ASC) {
                edges {
                    node {
                        name
                        icon
                        _meta {
                            uid
                            id
                        }
                    }
                }
            }
            allDocumentss {
                edges {
                    node {
                        attachment_3_4 {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        general_conditions {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        privacy_policy {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                        electronic_signature {
                            ... on PRISMIC__FileLink {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
